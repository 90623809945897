var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormGroup', {
    attrs: {
      "id": "streetAddress",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Street Address",
      "error": _vm.error['streetAddress']
    },
    on: {
      "input": _vm.onAddressChange
    },
    model: {
      value: _vm.form.streetAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "streetAddress", $$v);
      },
      expression: "form.streetAddress"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "locality",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Locality"
    },
    model: {
      value: _vm.form.locality,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "locality", $$v);
      },
      expression: "form.locality"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "region",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Region"
    },
    model: {
      value: _vm.form.region,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "postCode",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Postal Code",
      "error": _vm.error['postCode']
    },
    on: {
      "input": _vm.onAddressChange
    },
    model: {
      value: _vm.form.postCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postCode", $$v);
      },
      expression: "form.postCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "countryCode",
      "type": "select",
      "disabled": _vm.disabled,
      "config": {
        allowSearch: true
      },
      "label": "Country",
      "error": _vm.error['countryCode'],
      "options": _vm.filteredCountries
    },
    on: {
      "input": _vm.onAddressChange
    },
    model: {
      value: _vm.form.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "countryCode", $$v);
      },
      expression: "form.countryCode"
    }
  }), _vm.form.countryCode && _vm.filteredJurisdictions.length > 1 ? _c('FormGroup', {
    attrs: {
      "id": "jurisdiction",
      "type": "select",
      "disabled": _vm.loadingAction.listCountries,
      "label": "Jurisdiction",
      "error": _vm.error['jurisdiction'],
      "options": _vm.filteredJurisdictions
    },
    model: {
      value: _vm.form.jurisdiction,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jurisdiction", $$v);
      },
      expression: "form.jurisdiction"
    }
  }) : _vm._e(), !_vm.hideLatLng ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "autoLatLng",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: false
      }, {
        label: 'No',
        value: true
      }],
      "label": "Override Lat/Long",
      "error": _vm.error['autoLatLng'],
      "description": "Override the Lat/Long values for this Asset (Select 'No' if you are unsure)"
    },
    on: {
      "input": function input(e) {
        return e ? _vm.onAddressChange() : null;
      }
    },
    model: {
      value: _vm.form.autoLatLng,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "autoLatLng", $$v);
      },
      expression: "form.autoLatLng"
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "latitude",
      "type": "text",
      "label": "Latitude",
      "disabled": _vm.form.autoLatLng
    },
    model: {
      value: _vm.form.latitude,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "latitude", $$v);
      },
      expression: "form.latitude"
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "longitude",
      "type": "text",
      "label": "Longitude",
      "disabled": _vm.form.autoLatLng
    },
    model: {
      value: _vm.form.longitude,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "longitude", $$v);
      },
      expression: "form.longitude"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2 align-self-center"
  }, [_vm.assetLoadingAction.getGeocoordinates ? _c('Spinner', {
    attrs: {
      "size": "sm",
      "text": ""
    }
  }) : _vm._e()], 1)])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }