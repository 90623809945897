<template>
  <div>
    <FormGroup
      id="streetAddress"
      v-model="form.streetAddress"
      type="text"
      :disabled="disabled"
      label="Street Address"
      :error="error['streetAddress']"
      @input="onAddressChange"
    />
    <FormGroup id="locality" v-model="form.locality" type="text" :disabled="disabled" label="Locality" />

    <FormGroup id="region" v-model="form.region" type="text" :disabled="disabled" label="Region" />

    <FormGroup
      id="postCode"
      v-model="form.postCode"
      type="text"
      :disabled="disabled"
      label="Postal Code"
      :error="error['postCode']"
      @input="onAddressChange"
    />

    <FormGroup
      id="countryCode"
      v-model="form.countryCode"
      type="select"
      :disabled="disabled"
      :config="{ allowSearch: true }"
      label="Country"
      :error="error['countryCode']"
      :options="filteredCountries"
      @input="onAddressChange"
    />

    <FormGroup
      v-if="form.countryCode && filteredJurisdictions.length > 1"
      id="jurisdiction"
      v-model="form.jurisdiction"
      type="select"
      :disabled="loadingAction.listCountries"
      label="Jurisdiction"
      :error="error['jurisdiction']"
      :options="filteredJurisdictions"
    />

    <div v-if="!hideLatLng">
      <FormGroup
        id="autoLatLng"
        v-model="form.autoLatLng"
        type="radio"
        :options="[
          { label: 'Yes', value: false },
          { label: 'No', value: true }
        ]"
        label="Override Lat/Long"
        :error="error['autoLatLng']"
        description="Override the Lat/Long values for this Asset (Select 'No' if you are unsure)"
        @input="e => (e ? onAddressChange() : null)"
      />

      <div class="row">
        <div class="col-md-5">
          <FormGroup id="latitude" v-model="form.latitude" class="mb-0" type="text" label="Latitude" :disabled="form.autoLatLng" />
        </div>
        <div class="col-md-5">
          <FormGroup id="longitude" v-model="form.longitude" class="mb-0" type="text" label="Longitude" :disabled="form.autoLatLng" />
        </div>
        <div class="col-md-2 align-self-center">
          <Spinner v-if="assetLoadingAction.getGeocoordinates" size="sm" text=""></Spinner>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';
import Spinner from '@/components/Spinner';

export default {
  name: 'AddressForm',
  components: {
    FormGroup,
    Spinner
  },
  extends: Form,
  props: {
    hideLatLng: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      countries: 'util/countries',
      jurisdictions: 'util/jurisdictions',
      loadingAction: 'util/loadingAction',
      assetLoadingAction: 'asset/loadingAction'
    }),
    filteredCountries() {
      if (this.countries.length === 0) {
        return [];
      }

      const primaryCountryCodes = ['gb', 'ie', 'fr', 'de', 'es', 'it', 'us', 'ca'];

      const primaryCountries = primaryCountryCodes.map(code => {
        return this.countries.find(c => c.code === code);
      });

      const sortedCountries = [
        ...primaryCountries.map(c => ({ value: c.code, label: c.fullName })),
        { label: '-------------', value: null, disabled: true },
        ...this.countries.filter(c => !primaryCountryCodes.includes(c.code)).map(c => ({ value: c.code, label: c.fullName }))
      ];

      return sortedCountries;
    },
    filteredJurisdictions() {
      let jurisdictions = this.jurisdictions;

      if (this.form.countryCode) {
        jurisdictions = jurisdictions.filter(j => j.code.startsWith(`${this.form.countryCode}`));
      }

      if (jurisdictions.length === 1) {
        this.$set(this.form, 'jurisdictionCode', jurisdictions[0].code);
      }

      return jurisdictions.map(j => {
        return { value: j.code, label: j.name };
      });
    }
  },
  created() {
    if (this.countries.length === 0) {
      this.listCountries();
    }
  },
  methods: {
    ...mapActions({
      listCountries: 'util/listCountries',
      getGeocoordinates: 'asset/getGeocoordinates'
    }),
    async onClickFetchAddress() {
      const coords = await this.getGeocoordinates({ id: 0, data: { params: { address: this.form.address } } });

      if (!coords.lat) {
        this.$toasted.error('Could not find any geocoordinates for the above address/postcode');
      } else {
        this.$toasted.success('Updated lat and long from address');
        this.form.latitude = coords.lat;
        this.form.longitude = coords.lng;
      }
    },
    onAddressChange: debounce(async function () {
      if (this.form.autoLatLng) {
        const coords = await this.getGeocoordinates({ id: 0, data: { params: { address: this.form } } });

        if (coords.lat) {
          this.form.latitude = coords.lat;
          this.form.longitude = coords.lng;
        }
      }
    }, 500)
  }
};
</script>
